import React from 'react'
import { formatNumberToDecimal } from './../../../../utils/common.util'
import { Center, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { useLanguageContext } from '../../../../context/locale.context'
import { sectionDetailedTrack } from '../../../../containers/repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../../containers/repository/repo.utils'

function SATableChart({
	tableData,
	tableParams,
	showTableRecords,
	showResourceTable,
	showReporteeTable,
	isResourceView,
	isSaResourceLoading,
}) {
	const getKeys = () => {
		return Object.keys(tableData[0])
	}
	const {
		state: { locale },
	} = useLanguageContext()

	const getHeader = () => {
		const items = tableData
		const keys = getKeys()
		return items.map((header, index) => {
			if (index === 0) {
				return keys.map((key, i) => (
					<Th key={i} fontWeight='bold'>
						{header[key]}
					</Th>
				))
			}
			return null
		})
	}

	const getRowsData = () => {
		const items = tableData
		const keys = getKeys()
		return items.map((data, index) => {
			if (index !== 0) {
				return (
					<Tr key={index}>
						{keys.map((key, i) => {
							const isTotal = data[key] === 'Total'
							const isValue =
								(!isNaN(data[key]) && data[key]) || data[key] === 0
							const verticalTagId = data.flag_1_1.vertical_tag_id
							const verticalTagName = data.flag_1_1.vertical_name
							const asset_id = data.flag_1_1.target_asset_id
							const reporteeName = data.resource_name
							const isDisabled = reporteeName && reporteeName === 'Total'
							return (
								<Td
									cursor={!isTotal && !isDisabled ? 'pointer' : 'default'}
									key={i}
									minWidth={key === 'resource_name' ? '250px' : '120px'}
									//maxWidth={key === 'resource_name' ? '250px' : '120px'}
									fontWeight='bold'
									onClick={
										() => {
											sectionDetailedTrack({
												category: GA_CATEGORY_DASHBOARD,
												action: 'SA Table Chart',
											})
											if (isDisabled) {
												return
											} else {
												if (isValue) {
													showTableRecords({
														...tableParams,
														...data[key + '_1'],
														isSaSummary: true,
													})
												} else {
													if (!isTotal) {
														if (isResourceView) {
															showReporteeTable({
																verticalTagId,
																asset_id,
																reporteeName,
															})
														} else {
															showResourceTable(verticalTagId, verticalTagName)
														}
													}
												}
											}
										}
										// isDisabled
										//   ? null
										//   : isValue
										//   ? showTableRecords({
										//       ...tableParams,
										//       ...data[key + '_1'],
										//       isSaSummary: true,
										//     })
										//   : !isTotal
										//   ? isResourceView
										//     ? showReporteeTable({
										//         verticalTagId,
										//         asset_id,
										//         reporteeName,
										//       })
										//     : showResourceTable(verticalTagId, verticalTagName)
										//   : null
									}
								>
									{items[0][key]?.toLowerCase().includes('aov')
										? formatNumberToDecimal(data[key])
										: data[key]}
								</Td>
							)
						})}
					</Tr>
				)
			}
			return null
		})
	}

	if (tableData.length > 1) {
		return (
			<Table variant='striped' size='md'>
				<Thead>
					<Tr>{getHeader()}</Tr>
				</Thead>
				<Tbody>{getRowsData()}</Tbody>
			</Table>
		)
	} else if (isSaResourceLoading) {
		return null
	} else {
		return (
			<Center>
				<Text fontWeight='bold' color='black' fontSize='16px'>
					{locale['No Reportees Found']}
				</Text>
			</Center>
		)
	}
}

export default SATableChart

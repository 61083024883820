/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Select from 'react-select'
import { useDashboardContext } from '../../../context/dashboard.context'
import { dashboardConst } from '../../../utils/action.constant'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './filterItem.scss'
import {
	getStartDateOfMonth,
	getEndOfDay,
	getStartOfDay,
} from '../../../utils/date.utils'
import {
	FILTER_ID,
	HALF_YEARLY,
	MONTHS,
	QUATER,
} from '../../../utils/filter.constant'

const customStyles = {
	control: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: isDisabled ? '#c0c0c0' : 'white',
			color: '#fff',
			borderColor: '#e6e6e6',
			borderRadius: '6px',
			margin: '5px 0 10px',
			width: '160px',
		}
	},
	option: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: '#fff',
			color: '#000000',
			textAlign: 'left',
			width: '100%',
		}
	},
	menuPortal: provided => ({ ...provided, zIndex: 9999 }),
	menu: provided => ({ ...provided, zIndex: 9999 }),
}

function FilterItem({
	id,
	label,
	filterName,
	options = [],
	isDate = false,
	handleFilterChange,
	isDisabled,
	onFieldSearch,
}) {
	const defaultNullOption = { value: -1, label: 'NA' }

	const {
		state: { queryFilters, activeFilterOption },
		dispatch: dashboardDispatch,
	} = useDashboardContext()

	useEffect(() => {
		dashboardDispatch({
			type: dashboardConst.ADD_ACTIVE_FILTER_OPTION,
			activeFilterOption: { value: 0, label: 'All' },
		})
		if (isDate) {
			if (id === FILTER_ID.START_DATE) {
				handleFilterChange('startDateTime', getStartDateOfMonth())
			} else {
				handleFilterChange('endDateTime', getEndOfDay())
			}
		}
	}, [])

	// useEffect(() => {
	//   dashboardDispatch({
	//       type: dashboardConst.UPDATE_QUERY_FILTERS,
	//       filterName:'selectedCreator',
	//       value:activeFilterOption[0]
	//       })
	//     },
	//     [activeFilterOption]);

	return (
		<Box>
			<Text fontWeight='600' color='brand.800'>
				{label}
			</Text>
			{isDate ? (
				<DatePicker
					dateFormat={'dd-MM-yyyy'}
					selected={queryFilters ? queryFilters[filterName] : null}
					onChange={e => {
						if (e) {
							if (id === FILTER_ID.START_DATE) {
								handleFilterChange(filterName, getStartOfDay(e))
							} else {
								handleFilterChange(filterName, getEndOfDay(e))
							}
						}
					}}
					maxDate={
						!!queryFilters.selectedDateType
							? queryFilters.selectedDateType.value !== 3
								? getEndOfDay()
								: null
							: null
					}
					className='filter-date-input'
					disabled={isDisabled}
				/>
			) : (
				<Select
					value={queryFilters ? queryFilters[filterName] : defaultNullOption}
					// value={queryFilters ? (filterName==='selectedCreator' ?activeFilterOption[0]:queryFilters[filterName] ):defaultNullOption }
					options={
						id === FILTER_ID.TIME_SCALE &&
						!!queryFilters.selectedDateType &&
						queryFilters.selectedDateType.value !== 3
							? options.filter(list => list.value < 5)
							: id === FILTER_ID.PERIOD && !!queryFilters.selectedPeriodType
							? queryFilters.selectedPeriodType.value === 14
								? HALF_YEARLY
								: queryFilters.selectedPeriodType.value === 7
								? QUATER
								: queryFilters.selectedPeriodType.value === 6
								? MONTHS
								: []
							: options
					}
					onKeyDown={e =>
						e.key === 'Enter' ? handleFilterChange(filterName, e) : null
					}
					onChange={e => handleFilterChange(filterName, e)}
					styles={customStyles}
					menuPortalTarget={document.body}
					menuPosition={'fixed'}
					isDisabled={isDisabled}
					classNamePrefix='react-select'
				/>
			)}
		</Box>
	)
}

export default FilterItem

import React from 'react';
import {
  Table,
  Thead,
  Tr,
  Tbody,
  Spinner,
  Center,
  Td,
  Th,
} from '@chakra-ui/react';

const headers = [
  'Name',
  'Actual Score Achieved',
  'Total Score of Workflows',
  'No. of workflows submitted',
];

function SMEActivityTable({ isLoading, tableData }) {
  if (isLoading) {
    return (
      <Center p="10">
        <Spinner size="lg" color="brand.900" />
      </Center>
    );
  }

  const getAssetHeader = () => {
    return headers.map((header, index) => {
      return (
        <Th key={index} fontWeight="bold">
          {header}
        </Th>
      );
    });
  };

  const getAssetRowsData = () => {
    if (tableData)
      return tableData.map(activity => (
        <Tr key={activity.activity_type_name}>
          <Td width="20vw">{activity.activity_type_name}</Td>
          <Td>{activity.field_gamification_score_value}</Td>
          <Td>{activity.form_gamification_score_value}</Td>
          <Td>{activity.No_of_submissions}</Td>
        </Tr>
      ));
  };
  return (
    <Table variant="striped" size="md">
      <Thead>
        <Tr>{getAssetHeader()}</Tr>
      </Thead>
      <Tbody>{getAssetRowsData()}</Tbody>
    </Table>
  );
}

export default SMEActivityTable;

import { Center, Table, Text, Tbody, Thead, Tr, Th, Td } from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useLanguageContext } from '../../../../context/locale.context'

function SADrillDownTableChart({
	tableData,
	showTableRecords,
	isSaResourceLoading,
}) {
	const {
		state: { locale },
	} = useLanguageContext()

	const getRowsData = () => {
		if (tableData.length) {
			return tableData.map((item, i) => {
				return (
					<Tr key={i}>
						<Td>{item.label}</Td>
						<Td
							cursor={'pointer'}
							onClick={() => {
								showTableRecords({
									...item.link,
									...item,
								})
							}}
						>
							{item.value}
						</Td>
					</Tr>
				)
			})
		} else return null
	}

	if (tableData.length) {
		return (
			<Table size='md'>
				<Thead backgroundColor={localStorage.getItem('color')}>
					<Tr>
						<Th color='#FFFFFF'>Stage</Th>
						<Th color='#FFFFFF'>Count</Th>
					</Tr>
				</Thead>
				<Tbody>{getRowsData()}</Tbody>
			</Table>
		)
	} else if (isSaResourceLoading) {
		return null
	} else {
		return (
			<Center>
				<Text color='black' fontSize='10px'>
					{locale['No data to display']}
				</Text>
			</Center>
		)
	}
}

export default SADrillDownTableChart

import React from 'react'
import {
  Table,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
  Spinner,
  Center,
} from '@chakra-ui/react'
import { sectionDetailedTrack } from '../../../../containers/repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../../containers/repository/repo.utils'

const headers = [
  'Name',
  'Actual Score Achieved',
  'Total Score of Workflows',
  'No. of workflows submitted',
]

function SMEAssetTable({
  tableData,
  isLoading,
  showReporteesScore,
  disableSelfCall,
}) {
  if (isLoading) {
    return (
      <Center p="10">
        <Spinner size="lg" color="brand.900" />
      </Center>
    )
  }
  const getAssetHeader = () => {
    return headers.map((header, index) => {
      return (
        <Th key={index} fontWeight="bold">
          {header}
        </Th>
      )
    })
  }

  const getAssetRowsData = () => {
    if (Array.isArray(tableData)) {
      return tableData.map(asset => (
        <Tr key={asset.operating_asset_first_name}>
          <Td
            width="20vw"
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DASHBOARD,
                action: 'SME Gamification',
                label: 'Show Reportees Score',
              })
              showReporteesScore({ asset_id: asset.asset_id })
            }}
            cursor="pointer"
          >
            {asset.operating_asset_first_name}
          </Td>
          <Td>{asset.gamification_score}</Td>
          <Td>{asset.form_score || 0}</Td>
          <Td>{asset.submission_count || 0}</Td>
        </Tr>
      ))
    } else {
      return (
        <Tr>
          <Td
            width="20vw"
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DASHBOARD,
                action: 'SME Gamification',
                label: 'Show Other Asset Score',
              })
              if (!disableSelfCall)
                showReporteesScore({
                  asset_id: tableData.asset_id,
                  self: true,
                })
            }}
            cursor={disableSelfCall ? 'text' : 'pointer'}
          >
            {tableData.operating_asset_first_name}
          </Td>
          <Td>{tableData.gamification_score}</Td>
          <Td>{tableData.form_score || 0}</Td>
          <Td>{tableData.submission_count || 0}</Td>
        </Tr>
      )
    }
  }

  return (
    <Table variant="striped" size="md">
      <Thead>
        <Tr>{getAssetHeader()}</Tr>
      </Thead>
      <Tbody>{getAssetRowsData()}</Tbody>
    </Table>
  )
}

export default SMEAssetTable

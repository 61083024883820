import React from 'react'
import { getFormattedWidgetValue } from '../../../../utils/dashboard.utils'
import { Box, Center, Flex, Heading } from '@chakra-ui/react'
import { sectionDetailedTrack } from '../../../../containers/repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../../containers/repository/repo.utils'

//To do: Show target value
function NumericChart({
  onViewWidgetDrilldownData,
  widgetSourceData = {},
  widgetData = {},
}) {
  const { widgetTotalValue, jsonParams } = widgetSourceData

  const formattedWidgetTotalValue = getFormattedWidgetValue(widgetTotalValue)
  return (
    <Center p="10">
      <Heading
        cursor={formattedWidgetTotalValue !== 0 ? 'pointer' : 'default'}
        onClick={() => {
          sectionDetailedTrack({
            category: GA_CATEGORY_DASHBOARD,
            action: 'Numeric Chart',
            label: 'View Widget Drilldown Data',
          })
          formattedWidgetTotalValue !== 0 &&
            onViewWidgetDrilldownData(jsonParams)
        }}
        color="brand.800"
        as="h2"
        size="2xl"
      >
        {formattedWidgetTotalValue}
      </Heading>
    </Center>
  )
}

export default NumericChart

import React, { useState } from 'react'
import { getFormattedWidgetValue } from '../../../../utils/dashboard.utils'
import { Center, Heading, HStack, Box, Tag } from '@chakra-ui/react'
import { useLoadReportessSMEScore } from '../../../../hooks/dashboard.hooks'
import SMEAssetTable from '../sme-gamification/smeAssetTable'
import { useAuthContext } from './../../../../context/auth.context'
import { useAssetGamificationActivityList } from './../../../../hooks/dashboard.hooks'
import SMEActivityTable from './../sme-gamification/smeActivityTable'
import {
  GA_CATEGORY_DASHBOARD,
  sectionDetailedTrack,
} from '../../../../containers/repository/repo.utils'

function SMEGamificationChart({ widgetSourceData = {} }) {
  const { widgetTotalValue, parsedWidgetData } = widgetSourceData
  const [showTable, setShowTable] = useState(false)
  const [showActivityTable, setShowActivityTable] = useState(false)
  const [assetStack, setAssetStack] = useState([])

  const {
    state: { authData },
  } = useAuthContext()

  const {
    mutate: mutateReporteesSMEScore,
    data: reporteesSMEData,
    isLoading: isReporteesSMEDataLoading,
  } = useLoadReportessSMEScore()

  const {
    mutate: mutateGamificationActivityList,
    data: gamificationActivityListData,
    isLoading: isGamificationActivityLoading,
  } = useAssetGamificationActivityList()

  const removeAssetStack = asset => {
    let index = assetStack.findIndex(it => it.asset_id === asset.asset_id)
    if (index !== -1) {
      setAssetStack(prev => [...prev.slice(0, index)])
      showReporteesScore({ asset_id: asset.asset_id })
    }
  }

  const showGamificationActivityList = asset_id => {
    mutateGamificationActivityList({
      asset_id,
    })
  }

  const showReporteesScore = ({ asset_id, self = false }) => {
    if (!asset_id) {
      if ([1779].includes(widgetSourceData?.jsonParams?.tag_type_id))
        asset_id =
          widgetSourceData?.jsonParams?.filter_asset_id || authData.asset_id
      else asset_id = authData.asset_id
    }
    mutateReporteesSMEScore(
      {
        asset_id,
      },
      {
        onSuccess: data => {
          if (self) {
            showGamificationActivityList(data.self.asset_id)
            setShowActivityTable(true)
          } else {
            if (data.reportees.length === 0) {
              setShowActivityTable(true)
              showGamificationActivityList(data.self.asset_id)
            } else {
              setShowActivityTable(false)
            }
            setAssetStack(prev => [...prev, data.self])
          }
        },
      }
    )
  }

  const formattedWidgetTotalValue = getFormattedWidgetValue(widgetTotalValue)
  const smeTotalScore = getFormattedWidgetValue(
    (parsedWidgetData.length && parsedWidgetData[0]?.total_value) || 0
  )

  return (
    <>
      {showTable ? (
        <Box p={2}>
          <HStack spacing="12px" mb={3}>
            {assetStack.map(asset => {
              return (
                <Tag
                  cursor="pointer"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_DASHBOARD,
                      action: 'SME Gamification Chart',
                      label: 'Remove Asset From Breadcrumb',
                    })
                    removeAssetStack(asset)
                  }}
                >
                  {asset.operating_asset_first_name}
                </Tag>
              )
            })}
          </HStack>
          <SMEAssetTable
            isLoading={isReporteesSMEDataLoading}
            tableData={reporteesSMEData?.self}
            showReporteesScore={showReporteesScore}
            disableSelfCall={showActivityTable}
          />
          <Heading color="brand.800" as="h5" size="md" m={5}>
            {showActivityTable ? 'Workflow' : 'Reportees'}
          </Heading>
          {showActivityTable ? (
            <SMEActivityTable
              tableData={gamificationActivityListData}
              isLoading={isGamificationActivityLoading}
            />
          ) : (
            <SMEAssetTable
              isLoading={isReporteesSMEDataLoading}
              tableData={reporteesSMEData?.reportees}
              showReporteesScore={showReporteesScore}
            />
          )}
        </Box>
      ) : (
        <Center p="10">
          <Heading
            cursor={formattedWidgetTotalValue !== 0 ? 'pointer' : 'default'}
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DASHBOARD,
                action: 'SME Gamification Chart',
                label: 'Show Asset Table',
              })
              if (formattedWidgetTotalValue !== 0) {
                setShowTable(true)
                showReporteesScore({})
              }
            }}
            color="brand.800"
            as="h2"
            size="2xl"
          >
            {formattedWidgetTotalValue === 0
              ? formattedWidgetTotalValue
              : `${formattedWidgetTotalValue} / ${smeTotalScore}`}
          </Heading>
        </Center>
      )}
    </>
  )
}

export default SMEGamificationChart

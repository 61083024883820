/* eslint-disable array-callback-return */
import React from 'react';
import {
  Center,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { formatDDMMYYYYHHMM } from '../../../../utils/common.util';

function TableChart({ tableData }) {
  const getKeys = () => {
    return Object.keys(tableData[0]);
  };

  const getHeader = () => {
    const keys = getKeys();
    return keys.map((key, i) => {
      while (i !== 0) {
        return (
          <Th key={i} fontWeight="bold">
            {key}
          </Th>
        );
      }
    });
  };

  const getRowsData = () => {
    const items = tableData;
    const keys = getKeys();
    return items.map((data, index) => {
      return (
        <Tr key={index}>
          {keys.map((key, i) => {
            while (i !== 0) {
              return (
                <Td key={i}>
                  {key === 'Closed Date'
                    ? formatDDMMYYYYHHMM(data[key])
                    : data[key]}
                </Td>
              );
            }
          })}
        </Tr>
      );
    });
  };

  if (!!tableData && tableData.length) {
    return (
      <Table variant="striped" size="md">
        <Thead>
          <Tr>{getHeader()}</Tr>
        </Thead>
        <Tbody>{getRowsData()}</Tbody>
      </Table>
    );
  } else {
    return (
      <Center>
        <Text fontWeight="bold" color="black" fontSize="16px">
          No records found
        </Text>
      </Center>
    );
  }
}

export default TableChart;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
	Box,
	Center,
	IconButton,
	Icon,
	Flex,
	VStack,
	HStack,
	Spacer,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react'
import {
	useLoadSaReportee,
	useLoadSaResource,
	useLoadSaVerticalResource,
	useLoadWidgetDetails,
} from '../../../hooks/dashboard.hooks'
import { MdRefresh } from 'react-icons/md'
import { BsClipboardData } from 'react-icons/bs'
import { BiArrowBack } from 'react-icons/bi'
import FusionCharts from 'fusioncharts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import charts from 'fusioncharts/fusioncharts.charts'
import ReactFC from 'react-fusioncharts'
import Column2D from 'fusioncharts/fusioncharts.charts'
import ReactFusioncharts from 'react-fusioncharts'
import {
	WIDGET_CHART_ID,
	WIDGET_WORKSPACE_MODE,
} from '../../../utils/widget-constants'
import TableChart from './widget-type/TableChart'
import { PropagateLoader } from 'react-spinners'
import NumericChart from './widget-type/NumericChart'
import { dashboardConst } from '../../../utils/action.constant'
import { useDashboardContext } from '../../../context/dashboard.context'
import { useAuthContext } from '../../../context/auth.context'
import SATableChart from './widget-type/SATableChart'
import SMEGamificationChart from './widget-type/SMEGamificationChart'
// import {
// 	dataLayerTagManager,
// 	GA_CATEGORY_DASHBOARD,
// 	sectionDetailedTrack,
// } from '../../../containers/repository/repo.utils'
import SADrillDownTableChart from './widget-type/SADrillDownTableChart'
import { cloneDeep } from 'lodash'

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme)
FusionCharts.options.license({
	key: '4cE2orxG1H4G1C6A3C3B2G3E2E2I4B3C8D-16nf1D3D2iqH-7avB2C2eslD1H4B3B5A4D4G4E3C2A13A19A6C4D8D5B4F-10ffB2A5A7nA3C1E3cfqC5B7B2E-13yhF1C2F1suwC7E2B4D2C3A1A9A2A5E6F4hbbF2C8IE4rveE2F2E1C-22oC1F1G3D1eevB11B1E6F3E2B1D1B3E1C3D9A1E2G3l==',
	creditLabel: false,
})
charts(FusionCharts)

function WidgetItem({
	onWidgetViewChange,
	widgetData,
	widgetInlineData,
	queryFilters,
}) {
	const {
		data: widgetSourceData,
		isLoading,
		mutate: loadWidgetDetails,
	} = useLoadWidgetDetails()

	const {
		state: { authData },
	} = useAuthContext()

	const {
		data: saResourceData,
		isLoading: isSaResourceLoading,
		mutate: mutateloadResourceList,
	} = useLoadSaResource()

	const {
		isOpen: isResourceView,
		onOpen: onOpenResourceView,
		onClose: onCloseResourceView,
	} = useDisclosure()

	const [verticalName, setVerticalName] = useState()
	const [reporteeHirarchy, setReporteeHirarchy] = useState([])

	const { dispatch: dashboardDispatch } = useDashboardContext()
	const isMobileView = localStorage.getItem('IS_MOBILE_VIEW')

	useEffect(() => {
		return () => {
			setReporteeHirarchy([])
		}
	}, [])

	useEffect(() => {
		reloadSingleWidget()
	}, [widgetData])

	const reloadSingleWidget = flag => {
		loadWidgetDetails(
			{ widgetData, widgetInlineData, queryFilters, flag },
			{
				onSuccess: data => {
					if (
						!!data &&
						!!data.parsedWidgetData &&
						data.parsedWidgetData.length === 1
					) {
						if (
							!!queryFilters &&
							queryFilters.selectedSubApplication.value === 174
						) {
							onOpenResourceView()
							if (flag) {
								viewWidgetDrilldownData(data.data[0].link)
							}
						}
						showReporteeTable({
							verticalTagId: 0,
							asset_id: authData.asset_id,
							reporteeName: authData.operating_asset_first_name,
						})
						if (flag) {
							viewWidgetDrilldownData(data.data[0].link)
						}
					} else {
						setReporteeHirarchy([])
						if (flag) {
							viewWidgetDrilldownData(data.data[0].linkData)
						}
						return data
					}
				},
			}
		)
		onCloseResourceView()
	}

	const viewWidgetDrilldownData = singleWidgetData => {
		// dataLayerTagManager('button_click', {
		// 	viewName: 'Realtime Dashboard',
		// 	buttonName: 'Open Widget Drilldown',
		// })
		setSingleWidgetData(singleWidgetData)
		if (queryFilters.selectedSubApplication?.is_card_layout_enabled === 1) {
			onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_CARD_DATA)
		} else {
			onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_DATA)
		}
	}

	const setSingleWidgetData = (widgetData = {}) => {
		return dashboardDispatch({
			type: dashboardConst.LOAD_WIDGET_DATA,
			widgetData,
		})
	}

	const onChartClickEvent = () => {
		return {
			dataPlotClick: (eventObj, dataObj) => {
				let clickedData = {}
				if (widgetSourceData?.dataset) {
					clickedData = cloneDeep(
						widgetSourceData?.dataset?.[dataObj?.datasetIndex].data?.[
							dataObj?.dataIndex
						]?.linkData
					)
				} else {
					clickedData = cloneDeep(
						widgetSourceData?.data?.[dataObj?.dataIndex]?.linkData
					)
				}
				if (widgetData?.activity_id === 5267) {
					//daywise bugs status volume
					clickedData = cloneDeep(
						widgetSourceData?.dataset?.[dataObj?.datasetIndex].data?.[
							dataObj?.dataIndex
						]?.linkData
					)
				}

				clickedData.activity_title = widgetData?.activity_title
				// setDrilldownModalTitle(clickedData.activity_title)
				viewWidgetDrilldownData(clickedData)
			},
		}
	}

	const showResourceTable = (verticalTagId, verticalTagName) => {
		setVerticalName(verticalTagName)
		onOpenResourceView()
		mutateloadResourceList({
			widgetData,
			widgetInlineData,
			queryFilters,
			verticalTagId,
		})
	}

	const showReporteeTable = reportee => {
		const { asset_id, verticalTagId } = reportee
		onOpenResourceView()
		addReporteeToStack(reportee)
		mutateloadResourceList({
			widgetData,
			widgetInlineData,
			queryFilters,
			verticalTagId,
			reporteeId: asset_id,
			isReportee: true,
		})
	}

	const addReporteeToStack = reportee => {
		if (reporteeHirarchy.length) {
			const found = reporteeHirarchy.findIndex(
				asset => asset.asset_id === reportee.asset_id
			)
			if (found !== -1) {
				setReporteeHirarchy(reporteeHirarchy.slice(0, found + 1))
			} else {
				setReporteeHirarchy([...reporteeHirarchy, reportee])
			}
		} else {
			setReporteeHirarchy([reportee])
		}
	}

	const renderWidget = () => {
		const { widget_chart_id, widget_type_id } = widgetInlineData
		let widgetView = null
		if (isLoading) {
			widgetView = (
				<Center
					p='16'
					h={widget_chart_id !== WIDGET_CHART_ID.NUMERIC_CHART ? '400px' : 0}
				>
					<PropagateLoader size={12} color='gray' />
				</Center>
			)
		} else {
			switch (widgetInlineData.widget_chart_id) {
				case WIDGET_CHART_ID.VERTICAL_BAR_CHART:
					widgetView = (
						<ReactFusioncharts
							type={isMobileView ? 'scrollColumn2d' : 'column2d'}
							width='100%'
							height={isMobileView ? '600' : '400'}
							dataFormat='JSON'
							dataSource={!!widgetSourceData ? widgetSourceData : {}}
							events={onChartClickEvent()}
						/>
					)
					break
				case WIDGET_CHART_ID.STACKED_BAR_CHART:
					widgetView = (
						<ReactFusioncharts
							type={isMobileView ? 'scrollstackedcolumn2d' : 'stackedcolumn2d'}
							width='100%'
							height={isMobileView ? '600' : '400'}
							dataFormat='JSON'
							dataSource={!!widgetSourceData ? widgetSourceData : {}}
							events={onChartClickEvent()}
						/>
					)
					break
				case WIDGET_CHART_ID.PIE_CHART:
					widgetView = (
						<ReactFusioncharts
							type='pie2d'
							width='100%'
							height={isMobileView ? '700' : '400'}
							dataFormat='JSON'
							dataSource={!!widgetSourceData ? widgetSourceData : {}}
							events={onChartClickEvent()}
						/>
					)
					break
				case WIDGET_CHART_ID.TABLE_CHART:
					if ([542, 543].includes(widget_type_id)) {
						widgetView = (
							<SADrillDownTableChart
								tableData={
									!!widgetSourceData ? widgetSourceData.parsedWidgetData : []
								}
								isSaResourceLoading={isSaResourceLoading}
								tableParams={
									!!widgetSourceData ? widgetSourceData.jsonParams : []
								}
								showTableRecords={viewWidgetDrilldownData}
								showResourceTable={showResourceTable}
								showReporteeTable={showReporteeTable}
								isResourceView={isResourceView}
							/>
						)
					} else {
						widgetView = (
							<SATableChart
								tableData={
									isResourceView
										? !!saResourceData && saResourceData
										: !!widgetSourceData
										? widgetSourceData.parsedWidgetData
										: []
								}
								isSaResourceLoading={isSaResourceLoading}
								tableParams={
									!!widgetSourceData ? widgetSourceData.jsonParams : []
								}
								showTableRecords={viewWidgetDrilldownData}
								showResourceTable={showResourceTable}
								showReporteeTable={showReporteeTable}
								isResourceView={isResourceView}
							/>
						)
					}
					break
				case WIDGET_CHART_ID.SA2_TABLE_CHART:
					widgetView = <TableChart tableData={widgetSourceData} />
					break
				case WIDGET_CHART_ID.NUMERIC_CHART:
					if ([1770, 1779].includes(widgetSourceData?.jsonParams?.tag_type_id))
						widgetView = (
							<SMEGamificationChart widgetSourceData={widgetSourceData} />
						)
					else
						widgetView = (
							<NumericChart
								widgetSourceData={widgetSourceData}
								widgetData={widgetInlineData}
								onViewWidgetDrilldownData={viewWidgetDrilldownData}
								clearWidgetData={setSingleWidgetData}
							/>
						)
					break
				case WIDGET_CHART_ID.HORIZONTAL_BAR_CHART:
					widgetView = (
						<ReactFusioncharts
							type='bar2d'
							width='100%'
							height='100%'
							dataFormat='JSON'
							dataSource={!!widgetSourceData ? widgetSourceData : {}}
							events={onChartClickEvent()}
						/>
					)
					break
				default:
					break
			}
		}
		return widgetView
	}

	return (
		<Box
			boxShadow='md'
			borderRadius='md'
			bg='white'
			minH='200px'
			minW='fit-content'
			m={2}
		>
			<Flex>
				{isResourceView && !reporteeHirarchy ? (
					<>
						<IconButton
							bg='white'
							aria-label='Refresh'
							cursor='pointer'
							size='md'
							_focus={{
								bg: 'secondary',
							}}
							_hover={{
								bg: 'secondary',
							}}
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_DASHBOARD,
								// 	action: 'Widget Item',
								// 	label: 'Close Resource View',
								// })
								setReporteeHirarchy([])
								onCloseResourceView()
							}}
							icon={<Icon as={BiArrowBack} w={6} h={6} />}
						/>
					</>
				) : null}
				<Box p={2}>
					<Flex direction='column'>
						<Tooltip
							hasArrow
							placement='top'
							label={widgetData.activity_title}
							aria-label='A tooltip'
						>
							<Text
								fontSize='md'
								fontWeight='500'
								maxWidth='750px'
								isTruncated
								whiteSpace='wrap'
							>
								{isResourceView && !reporteeHirarchy
									? verticalName
									: widgetData.activity_title}
							</Text>
						</Tooltip>
						{!!queryFilters &&
						[406, 174, 408].includes(
							queryFilters.selectedSubApplication.value
						) &&
						!!reporteeHirarchy &&
						reporteeHirarchy.length ? (
							<Flex>
								{reporteeHirarchy.map((item, i) => (
									<>
										{i !== 0 ? '/' : null}
										<Text
											fontWeight='bold'
											onClick={() => {
												// sectionDetailedTrack({
												// 	category: GA_CATEGORY_DASHBOARD,
												// 	action: 'Widget Item',
												// 	label: 'Show Reportee Table',
												// })
												showReporteeTable(item)
											}}
											color={localStorage.getItem('color')}
											cursor='pointer'
											textDecoration='underline'
										>
											{' '}
											{item.reporteeName.includes('(')
												? item.reporteeName.split('(')[0]
												: item.reporteeName}{' '}
											{/* {item.reporteeName}{' '} */}
										</Text>
									</>
								))}
							</Flex>
						) : null}
					</Flex>
				</Box>
				<Spacer />
				<HStack p='2'>
					<IconButton
						bg='white'
						borderRadius='md'
						boxShadow='md'
						aria-label='Refresh'
						cursor='pointer'
						size='sm'
						_focus={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_DASHBOARD,
							// 	action: 'Widget Item',
							// 	label: 'Refresh Single Widget',
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Realtime Dashboard',
							// 	buttonName: 'Widget Refresh',
							// })
							reloadSingleWidget()
						}}
						icon={<Icon as={MdRefresh} w={4} h={4} />}
					/>
					{widgetInlineData.widget_chart_id === WIDGET_CHART_ID.PIE_CHART && (
						<IconButton
							bg='white'
							borderRadius='md'
							boxShadow='md'
							aria-label='Refresh'
							cursor='pointer'
							size='sm'
							_focus={{
								bg: 'secondary',
							}}
							_hover={{
								bg: 'secondary',
							}}
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_DASHBOARD,
								// 	action: 'Widget Item',
								// 	label: 'Toatal Widget data',
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Realtime Dashboard',
								// 	buttonName: 'Total widgetData',
								// })
								reloadSingleWidget(true)
							}}
							icon={<Icon as={BsClipboardData} w={4} h={4} />}
						/>
					)}
				</HStack>
			</Flex>
			<Box>{renderWidget()}</Box>
		</Box>
	)
}

export default WidgetItem
